import Vue from 'vue'
import App from './App.vue'
import router from './router'



//import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'


// CSS
import '@/assets/styles/main.css';
import '@/assets/styles/bootstrap5.css';
import '@/assets/styles/all.min.css';

/* add font awesome icon component */
Vue.component('font-awesome-icon', FontAwesomeIcon);


new Vue({
  el: '#app',
  router,
  template: '<App/>',
  components: { App },
  render: h => h(App)

})