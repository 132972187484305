<template>
  <div id="app" class="container-fluid pt-5 pb-5">
    <router-view></router-view>
  </div>
</template>
<script>



export default {
  name: 'App',
  components: {
  }
}
</script>

<style>


#app {
 
}
</style>
