<template>
  <div class="d-flex align-items-center h-100">
      <div class="col-12 text-center">  
        Live Page
        <router-link to="/">home</router-link>
      </div>
  </div>
</template>

<script>
export default {
  name: 'LivePage',
  props: {
    msg: String
  }
}
</script>