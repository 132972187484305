<template>
   <div class="row align-items-center justify-content-center">
      <div class="col-lg-9 col-12">
        <slide-player></slide-player>
      </div>      
  </div>
</template>

<script>

import SlidePlayer from "@/components/SlidePlayer.vue";

export default {
  components: { SlidePlayer },
  name: 'HomePage',
  props: {
   
  }
}
</script>