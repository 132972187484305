<template>
    <div class="m-2 table-responsive">


    
      <table v-if="matrix" class="table table-borderless table-sm p-0 m-0">
        <tr><td colspan="7">{{ months[$props.month]}} {{ $props.year }}</td></tr>
        <tr class="small">
          <th style="width:14.27%">Mo.</th>
          <th style="width:14.27%">Di.</th>
          <th style="width:14.27%">Mi.</th>
          <th style="width:14.27%">Do.</th>
          <th style="width:14.27%">Fr.</th>
          <th style="width:14.27%">Sa.</th>
          <th style="width:14.27%">So.</th>
        </tr>
        <tr v-for="days in matrix" v-bind:key="days[0]">
          <td :class=" (day.text) ? 'notnull' : ''"  class="p-0" v-for="day in days" v-bind:key="day.day" v-on:click="$emit('dayClicked',day)" > 
            <div v-if="day.day" :class="'position-relative ' + ((daysData[day.day] && daysData[day.day].imgCounter) ? 'exists' :'') ">{{ day.text }}<i v-if="daysData[day.day] && daysData[day.day].events" style="color:#009688;top:2px;right:2px" class="position-absolute fa-solid fa-circle-exclamation"></i></div>          
          </td>         
        </tr> 
      </table>

    </div>
</template>

<script>




export default {
    name: 'PlayerCalendar', 
    props: ['year', 'month', 'daysData'],


    data: function()
    {
      return {        
        months:{
          1:'Januar',
          2:'Februar',
          3:'März',
          4:'April',
          5:'Mai',
          6:'Juni',
          7:'Juli',
          8:'August',
          9:'September',
          10:'Oktober',
          11:'November',
          12:'Dezember'
        },
        matrix:null
      }
    },

    methods: {

      calcMonthMask: function() {

        var week = {1:{},2:{},3:{},4:{},5:{},6:{},7:{}};
        var matrix = []

        var year = this.$props.year;
        var month = this.$props.month;  

        var firstDayOfMonthDate = new Date(new Date(year, month).setUTCDate(0));
        var lastDayOfMonthDate = new Date(year, month, 0);
        var firstDayOfMonthInWeekDigit = firstDayOfMonthDate.getDay();
        var lastDayOfMonthDigit = lastDayOfMonthDate.getDate();

        // Montag ersta Tag in Woche
        if (firstDayOfMonthInWeekDigit == 0)
          firstDayOfMonthInWeekDigit = 7;
        
        var weekCounter = 0;
        var dayCounter = 1;
        
        for (var i=firstDayOfMonthInWeekDigit; dayCounter<=lastDayOfMonthDigit; i++) {
          var day = i - (weekCounter*7);
          week[day] = {};
          week[day].text = dayCounter;
          week[day].day = parseInt(dayCounter);
          week[day].month = parseInt(month);
          week[day].year = parseInt(year);
          matrix[weekCounter] = week;
          if (day%7 == 0) {
            weekCounter++;
            week={1:{},2:{},3:{},4:{},5:{},6:{},7:{}};
          }
          dayCounter++;         
        } 
        this.matrix = matrix;
        //console.log(matrix);
      }

    },

    mounted() {
      console.log(this.daysData);
      this.calcMonthMask();

    }
}
</script>

<style scoped>
table {
 border-collapse: separate;
    border-spacing: 2px;

}
table,
td {
  font-size:85%;
}
td, th { 
  text-align:center;
  border-radius:5px;
  padding:0px;
}
td div {
  padding:5px 0px 5px 0px;
}
td.notnull { 

    background-color:#ECEFF1;
    cursor:pointer;
}

td:hover.notnull { 
    background-color:#CFD8DC;
}

td:hover.notnull:has(>div.exists) { 
    background-color:#C8E6C9;
}

td div.exists {
  color:#43A047;
  font-weight:bold;
}

/* td Eltern Element von div.exissts */
td:has(>div.exists) {
  background-color:#E8F5E9;
}
</style>
