<template>
    <div>

        <div class="card text-center">
            <div class="card-body">
                <div class="position-relative"> 
                    <div @click="play" ref="playerButton" :class="'position-absolute play-button ' + (playerIsReady ? 'active' : 'inactive') + ' ' + (playerButtonVisible ? 'button-visible' : 'button-hidden')">
                        <i style="color:#fff" class="fa-solid fa-play"></i>
                    </div>
                    
                    <img v-if="lastImage" ref="playerImage" :src="'https://philip.joszko.eu/plants/' + lastImage.file" width="100%" /> 
      
                </div>
            </div>
        </div>

         <div class="card text-center  mt-4 mb-4">
            <div class="card-body">
                <div class="progress">
                    <div class="progress-bar" role="progressbar" aria-label="Example with label" :style="'width: ' + loaded + '%;'" :aria-valuenow="loaded" aria-valuemin="0" aria-valuemax="100">{{ loaded }}%</div>
                </div>
            </div>
        </div>

        <div class="card text-center  mt-4 mb-4">
            <div class="card-body">
                <div class="text-left"> 
                    <div ref="eventCard"></div> 

                    <div class="input-group input-group-sm">
                        <input type="hidden" ref="newEventDate" value="" />
                        <select class="form-control form-control-sm col-2" ref="newEventType">
                            <option value="20">Info</option>
                            <option value="30">Bewässerung</option>
                            <option value="10">sonstige</option>
                        </select>
                        <input :type="userLoggedIn ? 'text' : 'password'" class="form-control" ref="newEventMessage" :placeholder="userLoggedIn ? 'Ereignis beschreiben...' : 'Geben Sie Ihr Passwort ein'" aria-label="Ereignis beschreiben..." aria-describedby="basic-addon2">
                        <div class="input-group-append">
                            <input type="submit" class="btn btn-outline-secondary" @click="saveNewEvent" :value="( userLoggedIn ? 'Speichern' : 'LogIn')" />
                        </div>
                    </div>
                                   
                </div> 
            </div>
        </div>



        <div class="card text-center"> 
            <div class="card-body">
                
                <div class="row">
                    <template v-if="days"> 
                        <template v-for="(yearArray, yearNumber) in days" >
                            <div v-for="(monthDays, monthNumber) in yearArray" :key="monthNumber" class="col-12 col-md-6 col-lg-4">                         
                                <player-calendar @dayClicked="getPlayerFiles" :year="parseInt(yearNumber)" :month="parseInt(monthNumber)" :days-data="monthDays"></player-calendar>
                            </div> 
                        </template>
                    </template>
                </div>
                
            </div>
        </div>



       

    </div>
</template>

<script>

import $ from 'jquery'

import PlayerCalendar from "@/components/PlayerCalendar.vue";  

export default {
    components:{PlayerCalendar},
    name: 'SlidePlayer',
    props: {
    
    },

    data: function()
    {
      return {
        loaded:0,
        playerIsReady: false,
        playerButtonVisible: false,
        playerFiles: null,
        lastImage: null,
        days: null,
        userLoggedIn: false
      }
    },

    watch: {

        playerFiles(newValue) {

            var that = this;

            that.playerIsReady = false;

            that.loaded = 0;

            var filesToPreload = [];

            for (var file in newValue) {
                filesToPreload.push("https://philip.joszko.eu/plants/" + newValue[file].file );
            }

            that.$refs.playerImage.style.opacity = 0;

            this.preloadImages(filesToPreload,function(percentDone){
                that.loaded = percentDone;

                if ( percentDone >= 100)
                    that.playerIsReady = true;

            },function(){  

                that.playerIsReady = true;
                that.$refs.playerImage.style.opacity = 1;
            });
        }

    },

    mounted() {

        //var that = this;

        this.getInitData();       

    },

    methods: {

        logIn() {

            var that = this;

            $.ajax({ 
                xhrFields: { withCredentials:true }, 
                url: "https://philip.joszko.eu/components.php?action=login",
                method: 'POST',   
                data: 'p=' + that.$refs.newEventMessage.value,
                success: function(res) {                    
                    if (res) {
                        that.userLoggedIn = res.userLoggedIn;
                        that.$refs.newEventMessage.value = "";
                    }
                }
            });



        },

        saveNewEvent() {

            var that = this;

            if ( !that.userLoggedIn )
                return that.logIn();

            $.ajax({ 
                xhrFields: { withCredentials:true },
                url: "https://philip.joszko.eu/components.php?action=event",
                method: 'POST',   
                data: 'date=' + that.$refs.newEventDate.value + '&type=' + that.$refs.newEventType.value + '&message=' + that.$refs.newEventMessage.value,
                success: function(res) {
                    
                    if (res.days ) {
                        that.days = res.days;
                    }
                }
            });


        },

        play() {

            var that = this;

            // prepare imgArray
            var images = [];
            for (var file in that.playerFiles) {
                images.push(that.playerFiles[file].file);
            }

            var index=0;
            var len = images.length;

            //that.$refs.playerButton.classList.remove('button-visible');
            //that.$refs.playerButton.classList.add('button-hidden');
            that.playerButtonVisible = false;

            function doNext()
            {
                if ( images[index] ) {
                    console.log(" ... ");
                    that.$refs.playerImage.src = "https://philip.joszko.eu/plants/" + images[index];
                    index++;
                    that.$refs.playerImage.onload = function(){
                        setTimeout(doNext, 50);
                    }
                        
                } else {
                    //that.$refs.playerButton.classList.remove('button-hidden');                  
                    that.playerButtonVisible = true;
                    //that.$refs.playerButton.classList.add('button-visible');
                }               
            }

            if ( len > 0)
                doNext();

        },

        getPlayerFiles(day) {

            var that = this;

            that.playerButtonVisible = true;

            var now = new Date();

            that.$refs.newEventDate.value = new Date(day.year, parseInt(day.month)-1, (day.day < 10 ? '0' + day.day : day.day), now.getHours(), now.getMinutes()).toJSON();

            var html =  '<h4 class="text-center text-muted">' + day.day + '.' + day.month + '.' + day.year + '</h4>';        

            if ( that.days[day.year][day.month][day.day] && that.days[day.year][day.month][day.day]['events'] ) {                               
                for (var i=0; i<that.days[day.year][day.month][day.day]['events'].length; i++) {
                    var time = that.days[day.year][day.month][day.day]['events'][i]['date'].split(" ");
                    html += '<span class="small text-muted">' + time[1] + '</span><p class="pb-0 mb-0">' + that.days[day.year][day.month][day.day]['events'][i]['message'] + "</p><hr class='p-1 m-0'>"; 
                }                
            }
            that.$refs.eventCard.innerHTML = html; 

            if ( !that.days[day.year][day.month][day.day] || !that.days[day.year][day.month][day.day]['imgCounter'] )
                return;

            $.ajax({ 
                url: "https://philip.joszko.eu/components.php?action=getPlayerFiles&year=" + day.year +  "&month=" + day.month + "&day=" + day.day,
                method: 'GET',   
                success: function(res) {                  

                    if (res.playerFiles ) {
                        console.log(res);
                        that.playerFiles = res.playerFiles;
                    }
                }
            });
           
        },

        getInitData()
        {
            var that = this;

            $.ajax({ 
                xhrFields: { withCredentials:true },  
                url: "https://philip.joszko.eu/components.php?action=init",
                method: 'GET',   
                success: function(res) {

                    if (res.days ) {
                        that.days = res.days;
                    }

                    if (res.lastImage ) {
                        that.lastImage = res.lastImage;
                    }

                    if (res.userLoggedIn)
                        that.userLoggedIn = res.userLoggedIn;
                }
            });
        },

        loadImage(path) {
            var that = this;

            return new Promise((resolve, reject) => {
                const img = that.$refs.playerImage;// Image()
        
                img.crossOrigin = 'Anonymous' // to avoid CORS if used with Canvas
                img.src = path
                img.onload = () => {
                    resolve(img)
                }
                img.onerror = e => {
                    console.log("error");
                    reject(e)
                }
            });
        },

        async preloadImages(imgs, progress, success) { 
            var counter=0;
            var perc = 0;
            for (var i=0;i<imgs.length;i++) {
                try {
                    await this.loadImage(imgs[i]);                    
                } catch(e) {
                    console.log("Err");
                    console.log(e);
                }
                counter++;
                perc = Math.round((counter*100) / imgs.length);
                progress(perc);
            }
            success(counter); 
        }
    }
}
</script>

<style scoped>
.window {
    background-color:red;
}

.play-button {
    top:50%;
    left:50%;
    padding:15px 30px 15px 30px;
    background-color:red;
    border-radius: 15px;
    border: 2px solid red;
    box-shadow: 2px 2px 2px 1px rgba(0, 0, 0, .4);
    cursor:pointer;
}
.play-button i {
    font-size:160%;
}

.play-button.inactive {
    background-color:#cdcdcd;
    border-color:#cdcdcd;
  
}



@keyframes fadeOut {
  0% { opacity: 1; }
  100% { opacity: 0; }
}

.button-hidden { animation: fadeOut 0.5s;opacity:0 }
</style>